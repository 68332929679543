<template lang="html">
    <div class="autoPano">
        <img :src="(((item.linkItem||{}).firstImage||{}).url||'').replace('/0/0/0/0/', '/800/800/1/0/')">
        <div style="position:absolute;left:0;top:0;bottom:0;right:0;display:flex;justify-content:center;align-items:center">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            panoId: {
                type: String,
                required: true
            },
            name: {
                type: [String, Number],
                default: () => {
                    return 'autopano'
                }
            },
            ablePano: {
                type: [String, Number],
                default: () => {
                    return 'autopano'
                }
            }
        },
        data() {
            return {
                show: false
            }
        },
        mounted() {
            this.onscroll()
            window.addEventListener('scroll', this.onscroll)
        },
        destroyed() {
            window.removeEventListener('scroll', this.onscroll)
        },
        methods: {
            onscroll(event) {
                console.log(this.$el)
                if (window.scrollY >= this.$el.offsettop) {
                    this.$emit('turnable')
                }
                if (window.scrollY >= this.$el.offsettop) {
                    this.$emit('disable')
                }
            },
        },
        watch: {
            ablePano(old){
            }
        }
    }
</script>

<style lang="css" scoped>
</style>
